import React, {FormEvent, useEffect, useState} from 'react';

import AutoSuggestHighlightMatch from 'autosuggest-highlight/match';
import AutoSuggestHighlightParse from 'autosuggest-highlight/parse';
import {Field, FieldProps, Form, Formik, FormikActions, FormikProps} from 'formik';
import Autosuggest, {BlurEvent, SuggestionsFetchRequestedParams} from 'react-autosuggest';

import * as Yup from 'yup';

import {
    ESpan,
    FormInput,
    StyledAutoSuggestWrapper,
    StyledDatePicker,
    SubmitButton
} from "./Forms";

// import {Client, getClients, getPractitioners, getSessionTypes, Practitioner, SessionType} from "../utils/database";
import {AppointmentType, User} from "../utils/service";
import {API, fetchData} from "../utils/service";
import {StatusMessage} from "./core";

function getNextHalfHour(dt: Date) {
    const remainder = 30 - (dt.getMinutes() % 30);
    dt.setMinutes(remainder + dt.getMinutes(), 0, 0);
    return dt;
}

function getEarliestTimeOfDate(dt: Date) {
    dt.setHours(0, 0, 0, 0)
    return dt;
}

function getOneYearFromDate(dt: Date) {
    const year = dt.getFullYear();
    dt.setFullYear(year + 1);
    return dt;
}

interface FormValues {
    client_id: string;
    practitioner_id: string;
    session_type_id: string;
    start: Date;
    duration_minutes: number;
}

const CreateAppointment: React.FC<{}> = () => {

    const [message, setMessage] = React.useState("")
    // Details for getting and selecting on clients
    const [clients, setClients] = useState(new Array<User>());

    useEffect(() => {
        fetchData(API + 'clients/')
            .then(data => {
                setClients(data);
            });
    }, []);


    const getClientSuggestions = (value: string) => {
        // calculate suggestions for a given input value
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;

        return inputLength === 0 ? [] : clients.filter((client: User) =>
            (client.first_name.toLowerCase().slice(0, inputLength) === inputValue
                || client.last_name.toLowerCase().slice(0, inputLength) === inputValue)
        );
    };

    const getClientSuggestionValue = (suggestion: User) => `${suggestion.first_name} ${suggestion.last_name}`; // when clicked how to populate

    function renderClientSuggestion(suggestion: User, {query}: { query: string }) {// how to render the suggestion
        const suggestionText = ` ${suggestion.first_name} ${suggestion.last_name}`;
        const matches = AutoSuggestHighlightMatch(suggestionText, query);
        const parts = AutoSuggestHighlightParse(suggestionText, matches);

        return (
            <span className={'suggestion-content'}>
                <span className="name">
                    {
                        parts.map((part, index) => {
                            const className = part.highlight ? 'highlight' : '';
                            return (
                                <span className={className} key={index}>{part.text}</span>
                            );
                        })
                    }
                </span>
            </span>
        );
    }

    const [clientSuggestions, setClientSuggestions] = useState(new Array<User>());
    const [clientValue, setClientValue] = useState('');

    // Practitioner Section
    const [practitioners, setPractitioners] = useState(new Array<User>());

    useEffect(() => {
        fetchData(API + 'practitioners/')
            .then(data => {
                setPractitioners(data);
            });
    }, []);


    const getPractitionerSuggestions = (value: string) => {
        // calculate suggestions for a given input value
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;

        return inputLength === 0 ? practitioners : practitioners.filter((practitioner: User) =>
            (practitioner.first_name.toLowerCase().slice(0, inputLength) === inputValue
                || practitioner.last_name.toLowerCase().slice(0, inputLength) === inputValue)
        );
    };

    const getPractitionerSuggestionValue = (suggestion: User) => `${suggestion.first_name} ${suggestion.last_name}`; // when clicked how to populate

    function renderPractitionerSuggestion(suggestion: User, {query}: { query: string }) {// how to render the suggestion
        const suggestionText = ` ${suggestion.first_name} ${suggestion.last_name}`;
        const matches = AutoSuggestHighlightMatch(suggestionText, query);
        const parts = AutoSuggestHighlightParse(suggestionText, matches);

        return (
            <span className={'suggestion-content'}>
                <span className="name">
                    {
                        parts.map((part, index) => {
                            const className = part.highlight ? 'highlight' : '';
                            return (
                                <span className={className} key={index}>{part.text}</span>
                            );
                        })
                    }
                </span>
            </span>
        );
    }

    const [practitionerSuggestions, setPractitionerSuggestions] = useState(new Array<User>());
    const [practitionerValue, setPractitionerValue] = useState('');

    // Session Types Section
    const [sessionTypes, setSessionTypes] = useState(new Array<AppointmentType>());

    useEffect(() => {
        fetchData(API + 'events/appointment-types/')
            .then(data => {
                setSessionTypes(data);
            });
    }, []);


    const getSessionTypeSuggestions = (value: string) => {
        // calculate suggestions for a given input value
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;

        return inputLength === 0 ? sessionTypes : sessionTypes.filter((sessionType: AppointmentType) =>
            (sessionType.title.toLowerCase().slice(0, inputLength) === inputValue));
    };

    const getSessionTypeSuggestionValue = (suggestion: AppointmentType) => `${suggestion.title}`; // when clicked how to populate

    function renderSessionTypeSuggestion(suggestion: AppointmentType, {query}: { query: string }) {// how to render the suggestion
        const suggestionText = ` ${suggestion.title} `;
        const matches = AutoSuggestHighlightMatch(suggestionText, query);
        const parts = AutoSuggestHighlightParse(suggestionText, matches);

        return (
            <span className={'suggestion-content'}>
                <span className="name">
                    {
                        parts.map((part, index) => {
                            const className = part.highlight ? 'highlight' : '';
                            return (
                                <span className={className} key={index}>{part.text}</span>
                            );
                        })
                    }
                </span>
            </span>
        );
    }

    const [sessionTypeSuggestions, setSessionTypeSuggestions] = useState(new Array<AppointmentType>());
    const [sessionTypeValue, setSessionTypeValue] = useState('');

    // The form itself
    const getSchema = Yup.object().shape({
        client_id: Yup.number()
            .min(1, 'Select a valid client.')
            .required('Required'),
        practitioner_id: Yup.number()
            .min(1, 'Select a valid practitioner.')
            .required('Required'),
        session_type_id: Yup.number()
            .min(1, 'Select a valid session type.')
            .required('Required'),
        start: Yup.date()
            .min(getEarliestTimeOfDate(new Date()))
            .max(getOneYearFromDate(new Date())),
        duration_minutes: Yup.number()
            .min(0, 'Appointment too short!')
            .max(180, 'Appointment too long!')
    });

    const getValues = {
        client_id: '',
        practitioner_id: '',
        session_type_id: '',
        start: getNextHalfHour(new Date()),
        duration_minutes: 45,
    };

    return (
        <div>
            <h2>Create Appointment</h2>
            <StatusMessage message={message} />
            <Formik
                initialValues={getValues}
                validationSchema={getSchema}
                onSubmit={(values: FormValues, actions: FormikActions<FormValues>) => {
                    values = Object.assign(values, {});
                    console.log({values, actions});
                    console.log('===========');
                    console.log(JSON.stringify(values));
                    console.log('===========');
                    fetchData(API + 'events/appointments/', {
                        method: 'POST',
                        body: JSON.stringify(values)
                    })
                        .then(data => {
                            console.log(data);
                            setMessage(`Successfully created appointment for ${data.client.first_name} ${data.client.last_name}`);
                        })
                        .catch(err =>
                            console.log(err));
                    // alert(JSON.stringify(values, null, 2));
                    actions.setSubmitting(false);
                }}
                render={(formikBag: FormikProps<FormValues>) => (
                    <Form>
                        <Field
                            name="client_id"
                            render={({field, form}: FieldProps<FormValues>) => (
                                <div>
                                    <label>Client: </label>
                                    <StyledAutoSuggestWrapper>
                                        <Autosuggest
                                            id="client"
                                            {...field}
                                            getSuggestionValue={getClientSuggestionValue}
                                            inputProps={{
                                                placeholder: "Type a client's name",
                                                value: clientValue,
                                                type: 'search', // makes a visible 'x' in the box
                                                onChange: (e: FormEvent, {newValue}) => setClientValue(newValue),
                                                onBlur: (e: FormEvent,
                                                         params: BlurEvent<User> | undefined) => {
                                                    if (params == null) {
                                                        return;
                                                    }
                                                    if (params.highlightedSuggestion == null) {
                                                        // setClientValue('');
                                                    } else {
                                                        const suggestion = params.highlightedSuggestion;
                                                        // setClientValue(` ${suggestion.client_firstName} ${suggestion.client_lastName}`);
                                                        formikBag.setFieldValue('client_id', suggestion.id);
                                                    }
                                                },
                                            }}
                                            onSuggestionsFetchRequested={(params: SuggestionsFetchRequestedParams) =>
                                                setClientSuggestions(getClientSuggestions(params.value))}
                                            onSuggestionsClearRequested={() => setClientSuggestions(new Array<User>())}
                                            renderSuggestion={renderClientSuggestion}
                                            suggestions={clientSuggestions}
                                            onSuggestionSelected={(e: FormEvent, data) =>
                                                formikBag.setFieldValue('client_id', data.suggestion.id)}
                                        />
                                    </StyledAutoSuggestWrapper>
                                    <ESpan>
                                        {form.touched.client_id &&
                                        form.errors.client_id &&
                                        form.errors.client_id}
                                    </ESpan>
                                </div>
                            )}
                        />
                        <Field
                            name="practitioner_id"
                            render={({field, form}: FieldProps<FormValues>) => (
                                <div>
                                    <label>Practitioner: </label>
                                    <StyledAutoSuggestWrapper>
                                        <Autosuggest
                                            id="practitioner"
                                            {...field}
                                            getSuggestionValue={getPractitionerSuggestionValue}
                                            inputProps={{
                                                placeholder: "Type a practitioner's name",
                                                value: practitionerValue,
                                                type: 'search', // makes a visible 'x' in the box
                                                onBlur: (e: FormEvent,
                                                         params: BlurEvent<User> | undefined) => {
                                                    if (params == null) {
                                                        return;
                                                    }
                                                    if (params.highlightedSuggestion == null) {
                                                        // setPractitionerValue('');
                                                    } else {
                                                        const suggestion = params.highlightedSuggestion;
                                                        setPractitionerValue(` ${suggestion.first_name} ${suggestion.last_name}`);
                                                        formikBag.setFieldValue('practitioner_id', suggestion.id);
                                                    }
                                                },
                                                onChange: (e: FormEvent, {newValue}) => setPractitionerValue(newValue)
                                            }}
                                            shouldRenderSuggestions={() => true} // since this list is small, always show the suggestions
                                            onSuggestionsFetchRequested={(params: SuggestionsFetchRequestedParams) =>
                                                setPractitionerSuggestions(getPractitionerSuggestions(params.value))}
                                            onSuggestionsClearRequested={() => setPractitionerSuggestions(new Array<User>())}
                                            renderSuggestion={renderPractitionerSuggestion}
                                            suggestions={practitionerSuggestions}
                                            onSuggestionSelected={(e: FormEvent, data) =>
                                                formikBag.setFieldValue('practitioner_id', data.suggestion.id)}
                                        />
                                    </StyledAutoSuggestWrapper>
                                    <ESpan>
                                        {form.touched.practitioner_id &&
                                        form.errors.practitioner_id &&
                                        form.errors.practitioner_id}
                                    </ESpan>
                                </div>
                            )}
                        />
                        <Field
                            name="session_type_id"
                            render={({field, form}: FieldProps<FormValues>) => (
                                <div>
                                    <label>Session Type: </label>
                                    <StyledAutoSuggestWrapper>
                                        <Autosuggest
                                            id="sessionType"
                                            {...field}
                                            getSuggestionValue={getSessionTypeSuggestionValue}
                                            inputProps={{
                                                placeholder: "Select a Session Type",
                                                value: sessionTypeValue,
                                                type: 'search', // makes a visible 'x' in the box
                                                onChange: (e: FormEvent, {newValue}) => setSessionTypeValue(newValue),
                                                onBlur: (e: FormEvent,
                                                         params: BlurEvent<AppointmentType> | undefined) => {
                                                    if (params == null) {
                                                        return;
                                                    }
                                                    if (params.highlightedSuggestion == null) {
                                                        // setSessionTypeValue('');
                                                    } else {
                                                        const suggestion = params.highlightedSuggestion;
                                                        setSessionTypeValue(`${suggestion.title}`);
                                                        formikBag.setFieldValue('session_type_id', suggestion.id);
                                                    }
                                                },
                                            }}
                                            shouldRenderSuggestions={() => true} // since this list is small, always show the suggestions
                                            onSuggestionsFetchRequested={(params: SuggestionsFetchRequestedParams) =>
                                                setSessionTypeSuggestions(getSessionTypeSuggestions(params.value))}
                                            onSuggestionsClearRequested={() =>
                                                setSessionTypeSuggestions(new Array<AppointmentType>())}
                                            renderSuggestion={renderSessionTypeSuggestion}
                                            suggestions={sessionTypeSuggestions}
                                            onSuggestionSelected={(e: FormEvent, data) =>
                                                formikBag.setFieldValue('session_type_id', data.suggestion.id)}
                                        />
                                    </StyledAutoSuggestWrapper>
                                    <ESpan>
                                        {form.touched.session_type_id &&
                                        form.errors.session_type_id &&
                                        form.errors.session_type_id}
                                    </ESpan>
                                </div>
                            )}
                        />
                        <div>
                            <label>Session Start Time: </label>
                            <StyledDatePicker
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={15}
                                dateFormat="MMMM d, yyyy h:mm aa"
                                timeCaption="time"
                                maxDate={getOneYearFromDate(new Date())}
                                minDate={getEarliestTimeOfDate(new Date())}
                                onChange={e => formikBag.setFieldValue('start', e)}
                                selected={formikBag.values.start}
                            />
                            <ESpan>
                                {formikBag.touched.start &&
                                formikBag.errors.start &&
                                formikBag.errors.start}
                            </ESpan>
                        </div>
                        <Field
                            name="duration_minutes"
                            render={({field, form}: FieldProps<FormValues>) => (
                                <div>
                                    <label>Session Length (minutes): </label>
                                    <FormInput type="input" {...field} placeholder="45"/>
                                    <ESpan>
                                        {form.touched.duration_minutes &&
                                        form.errors.duration_minutes &&
                                        form.errors.duration_minutes}
                                    </ESpan>
                                </div>
                            )}
                        />
                        <SubmitButton type="submit">Submit</SubmitButton>
                    </Form>
                )}
            />
        </div>
    );
};


export default CreateAppointment;
