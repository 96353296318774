import React, {Dispatch, SetStateAction} from 'react';
import {Redirect} from 'react-router-dom';

// TODO: fix any
const Logout: React.FC<{ loggedIn: boolean, setLoggedIn: Dispatch<SetStateAction<boolean>> }> = (loggedIn, setLoggedIn) => {
    localStorage.removeItem('APIToken');
    loggedIn.setLoggedIn(false);
    return (
        <Redirect to="/" push />
    );
};

export default Logout;
