import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import styled from 'styled-components';

import About from './components/About';
import CreateAppointment from "./components/CreateAppointment";
import CreateClient from './components/CreateClient';
import Home from './components/Home';
import Login from './components/Login';
import Logout from './components/Logout';
import NavBar from './components/NavBar';

import Room from './routes/Room';
import VideoTeleConference from './routes/VideoTeleConference';

const Container = styled.div`
    height: '100vh';
    width: '100vw';
    background: linear-gradient(-5deg, #2152a0, #3369b1, #3fb0e5);
`;

interface VTCProps {
    configUrl: string;
}

interface UserContext {
    authenticated: boolean;
    user: object;
    token: object;
}


// TODO: review props and fix all of the goofy object shit I'm doing

const App: React.FC<VTCProps> = props => {

    useEffect(() => {
        const l = document.createElement('link');
        l.rel = 'stylesheet';
        l.href = '//cloud.typography.com/7773252/661942/css/fonts.css';
        document.head!.appendChild(l);
    });

    // TODO: check with a 200 against an endpoint
    const [loggedIn, setLoggedIn] = useState(localStorage.getItem('APIToken') !== null);

    // from https://reacttraining.com/react-router/web/example/auth-workflow
    // @ts-ignore
    function PrivateRoute({component: Component, ...rest}) {
        return (
            <Route
                {...rest}
                render={args =>
                    loggedIn ? (
                        <Component {...args} />
                    ) : (
                        <Redirect
                            to={{
                                pathname: "/login",
                                state: {from: args.location}
                            }}
                        />
                    )
                }
            />
        );
    }

    return (
        <Router>
            <Container>
                <NavBar loggedIn={loggedIn} />
                <Switch>
                    <Route path="/" exact component={Home}/>
                    <Route path="/login" exact
                     render={routeProps => (
                         <Login loggedIn={loggedIn} setLoggedIn={setLoggedIn} routeProps={routeProps}/>)}
                    />
                    <Route path="/logout" exact
                     render={routeProps => (
                         <Logout loggedIn={loggedIn} setLoggedIn={setLoggedIn} />)}
                    />
                    <PrivateRoute path="/vtc" exact component={VideoTeleConference}/>
                    <Route
                        path="/vtc/:roomName"
                        render={routeProps => (
                            <Room {...routeProps} configUrl={props.configUrl}/>
                        )}
                    />
                    <Route path="/about" exact component={About}/>
                    <PrivateRoute path="/create/client" component={CreateClient}/>
                    <PrivateRoute path="/create/appointment" component={CreateAppointment}/>
                </Switch>
            </Container>
        </Router>
    );
};

export default App;
