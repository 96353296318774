export function isoFormatDMY(d: Date) {
    function pad(n: number) {
        return (n < 10 ? '0' : '') + n;
    }
    return pad(d.getUTCDate()) + '/' + pad(d.getUTCMonth() + 1) + '/' + d.getUTCFullYear();
}

export function monthDateFormat(d: Date | string) {
    if (typeof d === 'string') {
        d = new Date(d);
    }
    function pad(n: number) {
        return (n < 10 ? '0' : '') + n;
    }
    return pad(d.getMonth() + 1) + '/' +  pad(d.getDate());
}

export function hoursMinutesFormat(d: Date | string) {
    if (typeof d === 'string') {
        d = new Date(d);
    }
    return d.getHours() + ':' + d.getMinutes();
}
