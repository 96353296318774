import DatePicker from "react-datepicker";
import PhoneInput from "react-phone-number-input";
import styled from "styled-components";

import 'react-datepicker/dist/react-datepicker.css';
import 'react-phone-number-input/style.css';

export const ESpan = styled.span`
    color: red;
`;

export const FormInput = styled.input`
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
`;

export const SubmitButton = styled.button`
    background-color: #4CAF50;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
        background-color: #45a049;
    }
`;
export const StyledDatePicker = styled(DatePicker)`
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    width: 250px;
`;
export const StyledPhoneInput = styled(PhoneInput)`
    // the react-phone-number-input needs display: inline-block; to fully look nice

    // this next block fixes the flag issue
    // for more info see https://github.com/catamphetamine/react-phone-number-input/issues/228
    .react-phone-number-input__icon {
        height: initial;
        border: 0;
    }

    .react-phone-number-input__country-select {
        // position : absolute;
        top      : 0;
        left     : 0;
        // height   : 100%;
        // width    : 100%;
        z-index  : 1;
        border   : 0;
        opacity  : 0;
        cursor   : pointer;
    }

    react-phone-number-input {
        display : inline-block;
    }

    .react-phone-number-input__row {
    /* This is done to stretch the contents of this component */
        // display : inline-block;
        // align-items : center;
        max-width : 300px;
    }

    .react-phone-number-input__country--native {
        position     : relative;
        align-self   : stretch;
        // display      : flex;
        // align-items  : center;
        margin-right : 0.5em;
    }

    .react-phone-number-input__input {
        padding: 12px 20px;
        margin: 8px 0;
        display: inline-block;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-sizing: border-box;
    }
`;

export const StyledAutoSuggestWrapper = styled.div`
    display: inline-block; // make the autosuggests not creep to new line

    .react-autosuggest__container {
        position: relative;
    }

    .react-autosuggest__input {
        padding: 12px 20px;
        margin: 8px 0;
        display: inline-block;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-sizing: border-box;
        -webkit-appearance: none; // remove customization on Safari family browsers
        width: 280px;
        // height: 30px;
        // padding: 10px 20px;
        // font-family: Helvetica, sans-serif;
        font-weight: 300;
        font-size: 16px;
        // border: 1px solid #aaa;
        // border-radius: 4px;
    }

    .react-autosuggest__input:focus {
        outline: none;
    }

    .react-autosuggest__container--open .react-autosuggest__input {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .react-autosuggest__suggestions-container {
        display: none;
    }

    .react-autosuggest__container--open .react-autosuggest__suggestions-container {
        display: block;
        position: absolute;
        top: 45px; // this places top correct relative to bottom of input box
        width: 280px;
        border: 1px solid #aaa;
        background-color: #fff;
        font-family: Helvetica, sans-serif;
        font-weight: 300;
        font-size: 16px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        z-index: 2;
    }

    .react-autosuggest__suggestions-list {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }

    .react-autosuggest__suggestion {
        cursor: pointer;
        padding: 10px 20px;
    }

    .react-autosuggest__suggestion:not(:first-child) {
        border-top: 1px solid #ddd;
    }

    .react-autosuggest__suggestion--focused {
        background-color: #0C7EAF;
        color: #fff;
    }

    .suggestion-content {
        display: flex;
        align-items: center;
        background-repeat: no-repeat;
    }

    .highlight {
        color: #0000ee;
        font-weight: bold;
    }

    .react-autosuggest__suggestion--focused .highlight {
        color: #120000;
    }
`;

