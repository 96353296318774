import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

const Entry = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 200px;
  background-color: #f1f1f1;
`;

const StyledNavLink = styled(NavLink)`
  display: block;
  color: #000;
  padding: 8px 16px;
  text-decoration: none;

  &:hover {
     background-color: #555;
  color: white;
  }

  &.active {
    color: orange;
  }
`;

export default function NavBar(isLoggedIn: any) {
    console.log("isLoggedIn IS");
    console.log(typeof(isLoggedIn));
    console.log(isLoggedIn);
    const LoginOrLogout = isLoggedIn.loggedIn ? <Entry><StyledNavLink to="/logout">Log Out</StyledNavLink></Entry> :
        <Entry><StyledNavLink to="/login">Log In</StyledNavLink></Entry>
    return (
        <div>
            <ul>
                <Entry><StyledNavLink exact to="/">Home</StyledNavLink></Entry>
                <Entry><StyledNavLink exact to="/about">About</StyledNavLink></Entry>
                <Entry><StyledNavLink to="/vtc">VTC</StyledNavLink></Entry>
                <Entry><StyledNavLink to="/create/client">New Client</StyledNavLink></Entry>
                <Entry><StyledNavLink to="/create/appointment">New Appointment</StyledNavLink></Entry>
                {LoginOrLogout}
            </ul>
        </div>
    );
}
