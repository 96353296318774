// API primitives
export interface User {
    readonly id: number;
    readonly uid: string;
    first_name: string;
    last_name: string;
    email: string;
    mobile_number: string;
    image: string | null;
}

export interface AppointmentType {
    readonly id: number;
    readonly uid: string;
    title: string;
    slug: string;
}

export interface Appointment {
    readonly uid: string;
    practitioner: User;
    client: User;
    practitioner_id: number;
    client_id: number;
    start: string;
    end: string;
    session_type: AppointmentType;
    session_type_id: number;
    room_name: string;
}

export interface Appointments {
    results: Appointment[];
}

// Client
export interface Client {
    readonly uid: string;
    first_name: string;
    last_name: string;
    mobile_number: string;
}


// Service Interfaces
interface ServiceInit {
    status: 'init';
}

interface ServiceLoading {
    status: 'loading';
}

interface ServiceLoaded<T> {
    status: 'loaded';
    payload: T;
}

interface ServiceError {
    status: 'error';
    error: Error;
}

export type Service<T> =
    | ServiceInit
    | ServiceLoading
    | ServiceLoaded<T>
    | ServiceError;


// constants
export const SITE = process.env.NODE_ENV === 'production' ? 'https://api.tetehealth.com' : 'http://localhost:8000';
export const DEFAULT_USER_GRAPHIC = SITE + '/media/User-220px.png';
export const API = SITE + '/api/';
const AUTH_URL = API + 'rest-auth/login/';
// API endpoint functions
////////////////////////////////////////////////////////////////////////
export const fetchData = (url: string, initOptions: object = {}) => {
    const token = localStorage.getItem('APIToken');
    if (token === null) {
        console.log('There was no APIToken set. Authenticating.');
        console.log('You need to authenticate!');
        // authenticate();
        console.log('APIToken should now be set.');
    } else {
        console.log('There was a token found.');
    }

    // add our Authorization header onto any options that were passed in
    initOptions = Object.assign(initOptions, {
        headers: {
            Authorization: `Token ${token}`,
            'Content-Type': 'application/json',
        }
    });

    return fetch(url, initOptions)
        .then(response => response.json());
};

export const fetchUnAuthData = (url: string, initOptions: object = {}) => {

    initOptions = Object.assign(initOptions, {
        headers: {
            'Content-Type': 'application/json',
        }
    });

    return fetch(url, initOptions)
        .then(response => response.json());
};

interface Credentials {
    email?: string;
    mobile_number?: string;
    password: string;
}

export function authenticate(creds: Credentials) {
    fetch(AUTH_URL, {
        method: 'POST',
        // mode: 'cors',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(creds),
    })
        .then(response => response.json())
        .then(data => window.localStorage.setItem('APIToken', data.key));
    console.log("Key saved.");
}
