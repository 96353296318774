import React from 'react';

import {Field, FieldProps, Form, Formik, FormikActions, FormikProps} from 'formik';
import * as Yup from 'yup';

import {ESpan, FormInput, StyledPhoneInput, SubmitButton} from "./Forms";
import {API, fetchData} from "../utils/service";
import {StatusMessage} from "./core";

interface FormValues {
    first_name: string;
    last_name: string;
    email: string;
    mobile_number: string;
    // dateOfBirth: Date;
    // insuranceGroupNumber: string;
    // insuranceIdNumber: string;
}

const CreateClient: React.FC<{}> = () => {

    const [message, setMessage] = React.useState("")

    const getSchema = Yup.object().shape({
        first_name: Yup.string()
            .min(2, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Required'),
        last_name: Yup.string()
            .min(2, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Required'),
        email: Yup.string()
            .email('Invalid email')
            .required('Required'),
        mobile_number: Yup.string()
        // .matches(/^\+[1-9]\d{1,14}$/) // ITU E.164
            .matches(/^\+1\d{10}$/, "Must be 10-digit number") // NANP
            .required('Mobile number is required'),
        // dateOfBirth: Yup.date()
        //     .min(new Date(1920, 0, 1))
        //     .max(new Date()),
        // insuranceGroupNumber: Yup.string()
        //     .matches(/^\d{5,20}$/, 'Must be 5-20 digit number'), // TODO: check if this is a good constraint
        // insuranceIdNumber: Yup.string()
        //     .matches(/^[\d\w]{5,20}$/, 'Must be 5-20 digit alphanumeric'), // TODO: check if this is a good constraint
    });

    const getValues = {
        first_name: '',
        last_name: '',
        email: '',
        mobile_number: '',
        // insuranceGroupNumber: '',
        // insuranceIdNumber: '',
        // dateOfBirth: new Date(1990, 0, 1),
    };

    return (
        <div>
            <h2>Create Client</h2>
            <StatusMessage message={message} />
            <Formik
                initialValues={getValues}
                validationSchema={getSchema}
                onSubmit={(values: FormValues, actions: FormikActions<FormValues>) => {
                    values = Object.assign(values, {});
                    console.log({values, actions});
                    console.log('===========');
                    console.log(JSON.stringify(values));
                    console.log('===========');
                    fetchData(API + 'clients/', {
                        method: 'POST',
                        body: JSON.stringify(values)
                    })
                    .then(data => {
                        console.log(data);
                        setMessage(`Successfully created user ${data.first_name} ${data.last_name}`);
                    })
                        .catch(err =>
                            console.log(err));
                    // alert(JSON.stringify(values, null, 2));
                    actions.setSubmitting(false);
                }}
                render={(formikBag: FormikProps<FormValues>) => (
                    <Form>
                        <Field
                            name="first_name"
                            render={({field, form}: FieldProps<FormValues>) => (
                                <div>
                                    <label>First Name: </label>
                                    <FormInput type="text" {...field} placeholder="Jane"/>
                                    <ESpan>
                                        {form.touched.first_name &&
                                        form.errors.first_name &&
                                        form.errors.first_name}
                                    </ESpan>
                                </div>
                            )}
                        />
                        <Field
                            name="last_name"
                            render={({field, form}: FieldProps<FormValues>) => (
                                <div>
                                    <label>Last Name: </label>
                                    <FormInput type="text" {...field} placeholder="Doe"/>
                                    <ESpan>
                                        {form.touched.last_name &&
                                        form.errors.last_name &&
                                        form.errors.last_name}
                                    </ESpan>
                                </div>
                            )}
                        />
                        <Field
                            name="email"
                            render={({field, form}: FieldProps<FormValues>) => (
                                <div>
                                    <label>E-mail Address: </label>
                                    <FormInput type="text" {...field} placeholder="username@example.com"/>
                                    <ESpan>
                                        {form.touched.email &&
                                        form.errors.email &&
                                        form.errors.email}
                                    </ESpan>
                                </div>
                            )}
                        />
                        <div>
                            <label>Mobile Number:</label>
                            <StyledPhoneInput
                                country="US"
                                placeholder="Enter mobile number"
                                onChange={e => formikBag.setFieldValue('mobile_number', e)}
                                value=""
                            />
                            <ESpan>
                                {formikBag.touched.mobile_number &&
                                formikBag.errors.mobile_number &&
                                formikBag.errors.mobile_number}
                            </ESpan>
                        </div>
                        {/*<div>*/}
                        {/*    <label>Date of Birth:</label>*/}
                        {/*    <StyledDatePicker*/}
                        {/*        showYearDropdown*/}
                        {/*        dateFormatCalendar="MMMM"*/}
                        {/*        scrollableYearDropdown*/}
                        {/*        maxDate={new Date()}*/}
                        {/*        minDate={new Date(1920, 0, 1)}*/}
                        {/*        onChange={e => formikBag.setFieldValue('dateOfBirth', e)}*/}
                        {/*        selected={formikBag.values.dateOfBirth}*/}
                        {/*    />*/}
                        {/*    <ESpan>*/}
                        {/*        {formikBag.touched.dateOfBirth &&*/}
                        {/*        formikBag.errors.dateOfBirth &&*/}
                        {/*        formikBag.errors.dateOfBirth}*/}
                        {/*    </ESpan>*/}
                        {/*</div>*/}
                        {/*<Field*/}
                        {/*    name="insuranceGroupNumber"*/}
                        {/*    render={({field, form}: FieldProps<FormValues>) => (*/}
                        {/*        <div>*/}
                        {/*            <label>Insurance Group Number: </label>*/}
                        {/*            <FormInput type="text" {...field} placeholder="123456"/>*/}
                        {/*            <ESpan>*/}
                        {/*                {form.touched.insuranceGroupNumber &&*/}
                        {/*                form.errors.insuranceGroupNumber &&*/}
                        {/*                form.errors.insuranceGroupNumber}*/}
                        {/*            </ESpan>*/}
                        {/*        </div>*/}
                        {/*    )}*/}
                        {/*/>*/}
                        {/*<Field*/}
                        {/*    name="insuranceIdNumber"*/}
                        {/*    render={({field, form}: FieldProps<FormValues>) => (*/}
                        {/*        <div>*/}
                        {/*            <label>Insurance ID Number: </label>*/}
                        {/*            <FormInput type="text" {...field} placeholder="ABC1234567890"/>*/}
                        {/*            <ESpan>*/}
                        {/*                {form.touched.insuranceIdNumber &&*/}
                        {/*                form.errors.insuranceIdNumber &&*/}
                        {/*                form.errors.insuranceIdNumber}*/}
                        {/*            </ESpan>*/}
                        {/*        </div>*/}
                        {/*    )}*/}
                        {/*/>*/}
                        <SubmitButton type="submit">Submit</SubmitButton>
                    </Form>
                )}
            />
        </div>

    );
};


export default CreateClient;
