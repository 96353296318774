import React from 'react';
import styled from 'styled-components'

const MessagingDiv = styled.div`
    .isa_info, .isa_success, .isa_warning, .isa_error {
    margin: 10px 0px;
    padding:12px;

    }
    .isa_info {
        color: #00529B;
        background-color: #BDE5F8;
    }
    .isa_success {
        color: #4F8A10;
        background-color: #DFF2BF;
    }
    .isa_warning {
        color: #9F6000;
        background-color: #FEEFB3;
    }
    .isa_error {
        color: #D8000C;
        background-color: #FFD2D2;
    }
    .isa_info i, .isa_success i, .isa_warning i, .isa_error i {
        margin:10px 22px;
        font-size:2em;
        vertical-align:middle;
    }
`;

export const StatusMessage: React.FC<{ message: string }> = ({message}) => {
    if (message) {
        return (
            <MessagingDiv>{message}</MessagingDiv>
        );
    } else {
        return (<div></div>);
    }
};

export const MainLayoutDiv = styled.div`
    display: grid;
    grid-row-gap: 25px;
    grid-template-rows: 80px auto;
`;

export const MainLayoutHeader = styled.div`
    // background-color: black;
    display: flex;
    flex-direction: row;
`;

export const MainLayoutContent = styled.div`
    // background-color: green;
    display: flex;
    flex-direction: row;
`;

export const HeroText = styled.div`
    font-family: 'Roboto', sans-serif;
    font-size: 3em;
`;

export const StyledButton = styled.button`
    background-color: #4CAF50;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
        background-color: #45a049;
    }
`;

export const SpacerDiv = styled.div`
    min-height: 10vh;
`;