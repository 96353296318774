import React from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';

import {Appointment, DEFAULT_USER_GRAPHIC} from "../utils/service";
import {hoursMinutesFormat, monthDateFormat} from "../utils/time";

const AppointmentEntry: React.FC<{ props: Appointment }> = ({props}) => (
    <tr>
        <td>/* <img height="38" alt={props.client.first_name} src={props.client.image
            ? props.client.image : DEFAULT_USER_GRAPHIC}/> */

            {props.client.first_name + " " + props.client.last_name}
        </td>
        <td>{monthDateFormat(props.start)} {hoursMinutesFormat(props.start)} - {hoursMinutesFormat(props.end)}</td>
        <td><Link to={`/vtc/${props.room_name}`}>{props.room_name}</Link></td>
    </tr>
);

const AppointmentTable = styled.table`
  width: 100%;
  border: 1px solid black;
  border-collapse: collapse;

  td, th {
    padding: 5px;
    border: 1px solid black;
  }
`;

export function AppointmentList(props: { appointments: Appointment[] }) {
    const {appointments} = props;
    const entries = appointments.map(ent => (
        <AppointmentEntry key={ent.uid} props={ent}/>
    ));

    return (
        <AppointmentTable>
            <thead>
            <tr>
                <th>Client</th>
                <th>Appointment Time</th>
                <th>VTC Room</th>
            </tr>
            </thead>
            <tbody>
            {entries}
            </tbody>
        </AppointmentTable>
    );
}
