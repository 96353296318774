import React, {useEffect, useState} from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import './VideoTeleConference.css';

import {AppointmentList} from '../components/AppointmentList';

import {API, Appointment, fetchData} from "../utils/service";

function VideoTeleConference(props: RouteComponentProps) {

    const [appointments, setAppointments] = useState(new Array<Appointment>());
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetchData(API + 'events/appointments/')
            .then(data => {
                setAppointments(data);
                setIsLoading(false);
            });
    }, []);

    if (isLoading) {
        return (
            <>
                <div className="container">
                    <h2>Upcoming Appointments:</h2>
                    <h3>Loading</h3>
                </div>
            </>
        );
    } else {
        return (
            <>
                <div className="container">
                    <h2>Upcoming Appointments:</h2>
                    <AppointmentList appointments={appointments}/>
                </div>
            </>
        );
    }

}

export default withRouter(VideoTeleConference);
