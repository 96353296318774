import {Field, FieldProps, Form, Formik, FormikActions, FormikProps} from "formik";
import React, {Dispatch, SetStateAction} from "react";
import * as Yup from "yup";
import {API} from "../utils/service";
import {ESpan, FormInput, SubmitButton} from "./Forms";

// import {authenticate} from "../utils/service";
import {RouteComponentProps, StaticContext} from "react-router";

interface FormValues {
    email: string;
    password: string;
}

// const Login: React.FC<{loggedIn: boolean, setLoggedIn: Dispatch<SetStateAction<boolean>>}> = (loggedIn, setLoggedIn) => {
const Login: React.FC<{ loggedIn: boolean, setLoggedIn: Dispatch<SetStateAction<boolean>>, routeProps: RouteComponentProps<any, StaticContext, any> }> = props => {
// {myVar: boolean, setMyVar?: Dispatch<SetStateAction<boolean>>}
    const getSchema = Yup.object().shape({
        email: Yup
            .string()
            .email()
            .required(),
        password: Yup
            .string()
            .min(4)
            .max(255)
            // .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*d)[a-zA-Zd]$/)
            .required(),
    });

    const getValues = {
        email: '',
        password: '',
    };

    return (
        <div>
            <h2>Please Log In</h2>
            <Formik
                initialValues={getValues}
                validationSchema={getSchema}
                onSubmit={(values: FormValues, actions: FormikActions<FormValues>) => {
                    values = Object.assign(values, {});
                    console.log({values, actions});
                    console.log('===========');
                    console.log(JSON.stringify(values));
                    console.log('===========');
                    fetch(API + 'rest-auth/login/', {
                        method: 'POST',
                        headers: {'Content-Type': 'application/json'},
                        body: JSON.stringify(values)
                    })
                        .then(data => data.json())
                        .then(data => {
                            console.log(data);
                            if ('key' in data) {
                                window.localStorage.setItem('APIToken', data.key);
                                console.log('Wrote key');
                                console.log(props);
                                // TODO: could do another test to verify 200
                                props.setLoggedIn(true);
                                console.log('You are now logged in');
                                const { from } = props.routeProps.location.state || { from: { pathname: "/" } };
                                props.routeProps.history.push(from);
                            }
                        })
                        .catch(err =>
                            console.log(err)
                        );
                    actions.setSubmitting(false);
                }}
                render={(formikBag: FormikProps<FormValues>) => (
                    <Form>
                        <Field
                            name="email"
                            render={({field, form}: FieldProps<FormValues>) => (
                                <div>
                                    <label>E-mail Address: </label>
                                    <FormInput type="text" {...field} placeholder="username@example.com"/>
                                    <ESpan>
                                        {form.touched.email &&
                                        form.errors.email &&
                                        form.errors.email}
                                    </ESpan>
                                </div>
                            )}
                        />
                        <Field
                            name="password"
                            render={({field, form}: FieldProps<FormValues>) => (
                                <div>
                                    <label>Password: </label>
                                    <FormInput type="password" {...field} />
                                    <ESpan>
                                        {form.touched.password &&
                                        form.errors.password &&
                                        form.errors.password}
                                    </ESpan>
                                </div>
                            )}
                        />
                        <SubmitButton type="submit">Submit</SubmitButton>
                    </Form>
                )}
            />
        </div>
    );
};

export default Login;
