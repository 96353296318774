import React from 'react';
import {SpacerDiv} from "../components/core";

export default function Home() {
    const spanStyle = {
        color: 'white',
        fontFamily: 'Roboto',
    };
    const divStyle = {
        textAlign: 'center' as 'center',
    };
    const logoContainerStyle = {
        position: 'relative' as 'relative',
        marginTop: '10%',
        verticalAlign: 'center' as 'center',
        textAlign: 'center' as 'center',
    };
    const vertAlignStyle = {
        verticalAlign: 'center' as 'center',
    };
    return (
        <div>
            {/*<SpacerDiv />*/}
            <div id="logo-container" style={logoContainerStyle}>
                <img style={vertAlignStyle} src={"images/Tete-Logo-White.svg"} alt="Tete Logo"/>
            </div>
            <SpacerDiv />
            <div style={divStyle}>
                <span style={spanStyle}>Copyright 2020 TeteHealth</span>
            </div>

        </div>
    );
}
