import React from 'react';
import Home from "./Home";

export default function About() {
    return (
        <div>
            <h2>Tete.</h2>
            <Home/>
        </div>
    );
}
